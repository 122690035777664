import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class XorEncryptionService {

  xorEncryptDecrypt(text:string |null, key = "safeViezu") {
    if(text === null) return text;
    if(typeof text == 'number') {
      text = `${text}`;
    }
    return text.split("").map((char, i) => 
        String.fromCharCode(char.charCodeAt(0) ^ key.charCodeAt(i % key.length))
    ).join("");
  }
}
